<template>
  <div class="fixation">
    <div class="fixation__title">Фиксация материалов</div>
    <div class="fixation__table">
      <div class="fixation__table-header">
        <div class="titles">
          <div class="titles__item" v-for="title in titles" :key="title.id">
            <span class="titles__item--md">{{ title.md }}</span>
            <span class="titles__item--xs">{{ title.xs }}</span>
          </div>
        </div>
      </div>
      <div class="fixation__table-body">
        <table-result-group
          v-for="(group, i) in tableBlocks"
          :group="group"
          :iterate-index="i"
          :key="`group--${i}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableResultGroup from '@/components/dump/Details/TableResultGroup'

const tableOrder = {
  'Площадь зоны': 0,
  'Тип теплоизоляции': 1,
  'Тип гидроизоляции': 2
}

const titles = [
  {
    id: 'q42llunmc',
    md: 'Угловая (C)',
    xs: 'зона С'
  },
  {
    id: 'w7cjur1vr',
    md: 'Парапетная (D)',
    xs: 'зона D'
  },
  {
    id: 'p3fg5kap3',
    md: 'Центральная (E)',
    xs: 'зона E'
  }
]

export default {
  components: { TableResultGroup },
  props: {
    tableData: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  name: 'TableResult',
  computed: {
    titles: () => titles,
    tableBlocks() {
      const orderedTable = []

      const table = Array.isArray(this.tableData)
        ? this.tableData
        : Object.values(this.tableData)

      table.forEach((block) => {
        const titles = block.map(item => item.title)
        const key = Object.keys(tableOrder)
          .map((key) => titles.find(title => title === key))
          .find(item => Boolean(item))
        orderedTable[tableOrder[key]] = block
      })

      return orderedTable
    }
  }
}
</script>

<style scoped lang="sass">
.fixation
  width: 100%
  max-width: rem(960)
  margin: rem(51) auto
  &__title
    font-weight: bold
    +media((font-size: (0: rem(18), 992: rem(24))))
    +media((line-height: (0: rem(27), 992: rem(29))))
    +media((margin: (0: 0 rem(24) rem(16), 992: 0 0 rem(34))))
    text-align: left
  &__table
    +media((padding: (0: rem(24), 992: rem(34) rem(40))))
    background: $white
    border-radius: rem(4)
    display: grid
    +media((grid-row-gap: (0: rem(16), 768: rem(34))))
    &-header
      display: grid
      +media((grid-template-columns: (768: 5fr 6fr)))
      +media((padding-bottom: (0: rem(16), 768: 0)))
      +media((box-shadow: (0: inset 0 rem(-1) 0 $ec, 768: none)))
      .titles
        +media((grid-column-start: (768: 2)))
        display: grid
        grid-template-columns: repeat(3, 1fr)
        &__item
          +media((min-height: (768: rem(34))))
          display: flex
          align-items: center
          justify-content: center
          font-style: normal
          font-weight: bold
          font-size: rem(14)
          line-height: 100%
          &:not(:last-child)
            box-shadow: inset rem(-1) 0 0 $ec
          &--xs
            +media((display: (375: none)))
          &--md
            +media((display: (0: none, 375: block)))
    &-body
      display: grid
      +media((grid-row-gap: (0: rem(16), 768: rem(17))))
</style>
