<template>
  <div :class="classes">
    <div class="table-row__title">
      {{ title }}
    </div>
    <div class="table-row__value">
      <template v-if="isSingleValue">
        <div class="item">
          {{ value }}
        </div>
      </template>
      <template v-else>
        <div v-for="(item, i) in value" :key="`item--${i}`" class="item">
          {{ item }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  name: 'TableResultRow',
  computed: {
    classes() {
      return {
        'table-row': true,
        'table-row--bold': this.row.isBold,
        'table-row--error': this.row.error
      }
    },
    title() {
      return this.row.title
    },
    value() {
      return this.row.value
    },
    isSingleValue() {
      return !Array.isArray(this.value)
    }
  }
}
</script>

<style scoped lang="sass">
.table-row
  display: grid
  +media((grid-template-columns: (768: 5fr 6fr)))
  align-items: center
  font-size: rem(14)
  +media((grid-row-gap: (0: rem(16), 768: 0)))
  &:not(:last-child)
    +media((padding-bottom: (0: rem(16), 768: 0)))
    +media((box-shadow: (0: inset 0 rem(-1) 0 $ec, 768: none)))
  &__title
    font-weight: bold
    line-height: 140%
    +media((text-align: (768: left)))
  &__value
    display: flex
    align-items: center
    justify-content: center
    line-height: 100%
    font-weight: 500
    .item
      width: 100%
      +media((min-height: (768: rem(34))))
      align-items: center
      display: flex
      justify-content: center
      &:not(:last-child)
        box-shadow: inset rem(-1) 0 0 $ec
  &--bold
    .table-row
      &__value
        font-weight: bold
  &--error
    .table-row
      &__value
        color: #E30713
</style>
