<template>
  <div class="common__pane print__pb">
    <div class="common__title">Параметры участка</div>
    <div class="details__sectorParams">
      <div class="details__sectorParams-item">
        <div class="details__sectorParams-item--single">
          <div class="title">Тип утеплителя</div>
          <div class="val">{{ insulationKit.currentType }}</div>
        </div>

        <div class="details__sectorParams-item--single">
          <div class="title">Способ фиксации утеплителя</div>
          <div class="val">{{ insulationKit.currentMethod }}</div>
        </div>

        <div class="details__sectorParams-item--single">
          <div class="title">Основание кровли</div>
          <div class="val">
            {{ baseType }}
          </div>
        </div>

        <div class="details__sectorParams-item--single" v-if="isTypeExist">
          <div class="title">Шаг между гофрами профлиста</div>
          <div class="val">{{ sector.base.type.size }} мм</div>
        </div>
        <div
          class="details__sectorParams-item"
          v-if="isTypeExist && sector.base.type.isDoubleFastener"
        >
          <div class="val"><strong>Крепление ГИ выполнено двойным крепежом</strong></div>
          <!-- <div class="title">Шаг между гофрами профлиста</div>
          <div class="val">{{ sector.base.type.isDoubleFastener }} мм</div> -->
        </div>
      </div>
      <div class="details__sectorParams-item">
        <div class="details__sectorParams-item--single">
          <div class="title">Тип гидроизоляции</div>
          <div class="val">{{ dampCourseMaterialName }}</div>
        </div>

        <div class="details__sectorParams-item--single">
          <div class="title">Способ фиксации гидроизоляции</div>
          <div class="val">{{ currentMounting }}</div>
        </div>
        <div class="details__sectorParams-item--single" v-for="size in sectorSizes" :key="size.id">
          <div class="title">{{ size.title }}</div>
          <div class="val">{{ size.val }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getMountingName, normalizeSectorParamsView } from '@/utils'

export default {
  props: {
    sector: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentMaterial: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dampCourseMaterialName: {
      type: String,
      default: '',
      required: true
    }
  },
  name: 'sectorParams',
  computed: {
    ...mapState({
      insulationTypes: state => state.insulationTypes,
      insulationMethods: state => state.insulationMethods,
      mountingTypes: state => state.mounting
    }),
    currentMounting() {
      return getMountingName(this.mountingTypes, this.sector)
    },
    isTypeExist() {
      return this.sector.base.hasOwnProperty('type')
    },
    isCustomBase() {
      return this.sector.base.type.isCustom
    },
    isSizeExist() {
      return this.currentMaterial.hasOwnProperty('size')
    },
    currentMaterialType() {
      return this.currentMaterial.types.find(type => type.id === this.sector.base.type.id)
    },
    sectorSizes() {
      return normalizeSectorParamsView(this.sector)
    },
    isInsulationEnabled() {
      return this.sector.insulation.isEnabled
    },
    insulationKit() {
      // console.log(this.isInsulationEnabled)
      // return { currentType: 'нет', currentMethod: 'нет' }
      if (this.isInsulationEnabled) {
        const { type, method } = this.sector.insulation.data
        const currentType = this.insulationTypes.find(t => t.id === type).name
        const currentMethod = this.insulationMethods.find(m => m.id === method).name
        return {
          currentType,
          currentMethod
        }
      } else {
        return { currentType: 'нет', currentMethod: 'нет' }
      }
    },
    insulationType() {
      return this.isInsulationEnabled ? this.sector.insulation.data.type : 'нет'
    },
    insulationMethod() {
      return this.isInsulationEnabled ? this.sector.insulation.data.method : 'нет'
    },
    baseType() {
      let str = this.currentMaterial.name
      if (this.isTypeExist && !this.isCustomBase) {
        str = `${str} ${this.currentMaterialType.name}`
      }
      if (this.isSizeExist) {
        str = `${str} ${this.currentMaterial.size}`
      }
      return str.trim()
    }
  }
}
</script>

<style scoped></style>
