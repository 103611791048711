<template>
  <div class="details__header-subNav">
    <div class="title">
      <simple-btn color="transparent" @btnClicked="focusInput">
        <template #icon>
          <span class="icon">
            <img src="@/assets/img/edit-title.svg" alt="" />
          </span>
        </template>
      </simple-btn>
      <input type="text" ref="input" v-model="computedSectorName" :readonly="!isEditable" />
    </div>
    <div class="buttons">
      <simple-btn
        color="grey"
        @btnClicked="deleteBtnHandler"
        :additional-classes="['trash']"
        v-if="isShowDeleteBtn"
      >
        <template #icon>
          <span class="icon">
            <img src="@/assets/img/trash--black.svg" alt="" />
          </span>
        </template>
      </simple-btn>
      <a class="edit" @click="edit()">
        <img src="@/assets/img/cog-white.svg" alt="cog" />
        Редактировать участок
      </a>
    </div>
  </div>
</template>

<script>
import SimpleBtn from '@/components/dump/SimpleBtn'
import { mapActions } from 'vuex'
export default {
  components: { SimpleBtn },
  props: {
    isShowDeleteBtn: {
      type: Boolean,
      required: true
    },
    sectorIndex: {
      type: String,
      default: null,
      required: true
    },
    sectorName: {
      type: String,
      default: '',
      required: true
    }
  },
  data: () => ({
    isEditable: false
  }),
  name: 'SectorTitle',
  methods: {
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    focusInput() {
      this.isEditable = true
      this.$refs.input.focus()
    },
    deleteBtnHandler() {
      this.$emit('deleteSector')
    },
    edit() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
      this.$router.push(`/wizard/${this.sectorIndex}/`)
    }
  },
  computed: {
    computedSectorName: {
      get() {
        return this.sectorName
      },
      set(value) {
        this.$emit('updateName', value)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.details
  &__header
    &-subNav
      display: flex
      justify-content: space-between
      +media((flex-direction: (320: column, 576: row)))
      +media((align-items: (320: flex-start, 576: center)))
      .title
        text-align: left
        display: flex
        flex-direction: row
        align-items: center
        +media((padding-bottom: (320: rem(16), 576: 0)))
        input
          +media((font-size: (320: rem(22), 992: rem(28))))
          +media((line-height: (320: rem(31), 992: rem(42))))
          font-style: normal
          font-weight: 500
          color: $black
          font-family: $main-font
          border: none
          background: transparent
          outline: none
          transition: .5s
          +media((max-width: (320: rem(230), 576: rem(240), 768: rem(350))))
          &:read-only
            pointer-events: none
          &:focus
            background: $white
            border: rem(1) solid $ec
            box-shadow: 0 rem(4) rem(12) rgba($black, 0.15)
            border-radius: rem(4)
        .simple-btn
          padding: 0
          opacity: .7
          +media((margin-right: (320: rem(13), 960: rem(20))))
          +media((width: (320: rem(26), 960: rem(40))))
          +media((height: (320: rem(32), 960: rem(51))))
          justify-content: center
          .icon
            margin: 0
          @media(any-hover: hover)
            &:hover
              opacity: 1
      .buttons
        display: flex
        .trash
          padding: 0
          width: rem(50)
          justify-content: center
          margin-right: rem(6)
          .icon
            margin: 0
            filter: invert(67%) sepia(7%) saturate(19%) hue-rotate(321deg) brightness(91%) contrast(81%)
          @media(any-hover: hover)
            &:hover
              background: $red-hover
              .icon
                filter: invert(1)
        .edit
          background: $red
          border-radius: rem(4)
          transition: .5s
          color: $white
          font-style: normal
          font-weight: bold
          +media((font-size: (0: rem(12), 400: rem(14))))
          line-height: rem(17)
          text-decoration: none
          display: flex
          flex-direction: row
          align-items: center
          border: none
          outline: none
          box-shadow: none
          cursor: pointer
          text-align: left
          // margin-left: rem(6)
          +media((padding: (320: rem(15) rem(12), 360: rem(15) rem(23) rem(15) rem(20), 768: rem(15) rem(27) rem(15) rem(22))))
          img
            +media((margin-right: (320: rem(8), 360: rem(10), 992: rem(20))))
            transition: .5s
          &:hover
            background: $red-hover
            img
              transform: rotate(-180deg)
</style>
