<template>
  <div class="table-group">
    <table-result-row
      v-for="(row, i) in group"
      :row="row"
      :key="`group--${iterateIndex}--row--${i}`"
    />
  </div>
</template>

<script>
import TableResultRow from '@/components/dump/Details/TableResultRow'
export default {
  props: {
    iterateIndex: {
      type: Number,
      required: true
    },
    group: {
      type: Array,
      required: true
    }
  },
  name: 'TableResultGroup',
  components: { TableResultRow }
}
</script>

<style scoped lang="sass">
.table-group
  display: grid
  +media((grid-row-gap: (0: rem(16), 768: rem(17))))
  &:not(:last-child)
    box-shadow: inset 0 rem(-1) 0 $ec
    +media((padding-bottom: (0: rem(16), 768: rem(17))))
</style>
