<template>
  <div class="common__pane details__pane">
    <div class="common__title">Параметры расчета</div>
    <div class="details__params">
      <div class="details__params-item">
        <div class="details__params-item--single">
          <div class="title">Ветровой район</div>
          <div class="val">
            {{ zoneName }}
          </div>
        </div>
        <div class="details__params-item--single">
          <div class="title">
            Нормативное значение<br />
            давления ветра W0
          </div>
          <div class="val">{{ pressure }}</div>
        </div>
      </div>
      <div class="details__params-item">
        <div class="details__params-item--single">
          <div class="title">Тип местности</div>
          <div class="val">
            {{ location.type }}
          </div>
        </div>
        <div class="details__params-item--single">
          <div class="title">
            Коэффициент надежности<br />
            по ветровой нагрузке
          </div>
          <div class="val">
            {{ location.coefficient }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="details__params-item">
        <div class="details__params-item--single">
          <div class="title">k(ze)</div>
          <div class="val">
            {{ sectorResult.paramK }}
          </div>
        </div>
        <div class="details__params-item--single">
          <div class="title">ζ(ze)</div>
          <div class="val">
            {{ sectorResult.paramE }}
          </div>
        </div>
        <div class="details__params-item--single">
          <div class="title">Коэфициент кореляции V-</div>
          <div class="val">{{ sectorResult.paramV }}</div>
        </div>
      </div>
      <div class="details__params-item">
        <div class="details__params-item--single">
          <div class="title">Аэродинамические коэфициенты</div>
          <div class="val">пиковые</div>
        </div>
        <div class="details__params-item--single">
          <div class="title__list">угловая зона</div>
          <div class="val">{{ sectorResult.paramAeroC }}</div>
          <div class="title__list">парапетная зона</div>
          <div class="val">{{ sectorResult.paramAeroD }}</div>
          <div class="title__list">центральная зона</div>
          <div class="val">{{ sectorResult.paramAeroE }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWindZoneName } from '@/utils/windZone'
import { getPressureText } from '@/components/helper'

export default {
  props: {
    location: {
      type: Object,
      default: () => ({}),
      required: true
    },
    sectorResult: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  name: 'CalculationParams',
  computed: {
    zoneName() {
      return getWindZoneName(this.location.zone)
    },
    pressure() {
      return getPressureText(this.location)
    }
  }
}
</script>

<style lang="sass">
.details

  &__params, &__sectorParams
    display: grid
    text-align: left
    color: $black
    +media((grid-row-gap: (0: rem(20), 992: rem(44))))
    +media((grid-column-gap: (992: rem(40))))
    +media((grid-template-columns: (320: 1fr, 650: repeat(2, 1fr))))
    .divider
      +media((grid-column: (650: span 2)))
      +media((margin: (0: rem(20) 0, 992: 0)))
      height: rem(1)
      background: $ec
    &-item
      //&:nth-child(2n)
        +media((padding-left: (320: 0, 992: rem(40))))
      &--single
        display: grid
        &:not(:last-child)
          +media((margin-bottom: (320: rem(19), 992: rem(24))))
        .title, .val
          font-style: normal
          font-weight: 500
          display: flex
          flex-direction: row
          align-items: center
          flex-wrap: wrap
          +media((font-size: (320: rem(13), 992: rem(14))))
          +media((line-height: (320: rem(19), 992: rem(20))))
        .val
          font-weight: bold
          // span
            padding-right: rem(5)
  &__params
    &-item
      &--single
        +media((grid-template-columns: (320: 15fr 9fr, 992: 7fr 3fr)))
        +media((grid-column-gap: (0: rem(13), 992: rem(20))))
        // .val
          +media((padding-left: (320: rem(13), 992: rem(20))))
        .title
          &__list
            font-weight: 500
            font-size: rem(12)
            line-height: rem(18)
            +media((margin-left: (320: rem(13), 992:  rem(20))))
            +media((margin-bottom: (320: rem(16), 992: rem(4))))
  &__sectorParams
    &-item
      &--single
        +media((grid-template-columns: (320: 5fr 4fr, 360: 1fr 1fr)))
        &:last-of-type
          padding-bottom: rem(10)
      &:first-of-type
        .val
          +media((padding-right: (650: rem(20), 800: 0)))
</style>
