<template>
  <section class="details" v-if="insulationMaterials.length">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="fullPage"
    ></loading>
    <div class="container">
      <div class="row">
        <div class="details__header">
          <bread-crumbs :sector-id="sectorId" />
          <sector-title
            :sector-index="sectorId"
            :sector-name="sectorFromState.sectorName"
            @updateName="updateSectorName"
            @deleteSector="showDeleteConfirmation"
            :is-show-delete-btn="sectors.length > 1"
          />
        </div>
        <calculation-params :sector-result="sectorResultFromState" :location="location" />
        <sector-params
          :sector="sectorFromState"
          :current-material="currentMaterial"
          :damp-course-material-name="dampCourseMaterialName"
        />
        <div class="common__title-details break">Схема участка</div>
        <div class="common__pane">
          <button class="print" @click="print">
            <img src="@/assets/img/print.svg" alt="print" />
          </button>
          <div class="details__figure">
            <svg-wrapper>
              <component
                :is="currentFigureComponent"
                :current="buildingData"
                :buildingHeight="buildingData.height"
                :rollWidthOptions="rollWidthOption"
              ></component>
            </svg-wrapper>
          </div>
        </div>
      </div>
    </div>
    <table-result :table-data="tableData" />
    <ResultNav isDetails />
    <Addons isInDetails />
    <Plugins />
    <Prefooter @savePdf="savePdf" isInDetails @toggleLoading="toggleLoading" />
    <transition name="fading">
      <DeleteConfirmation
        v-if="isShowDeleteConfirmation"
        @confirm="deleteSector"
        @cancel="isShowDeleteConfirmation = !isShowDeleteConfirmation"
      />
    </transition>
  </section>
</template>
<script>
import { saveAs } from 'file-saver'
import { formsComponents } from '@/utils'
import { prepareSectorsForPdf, prepareTotalMaterialsTableForPdf } from '@/utils/pdf'
import { getSectorResultPdf } from '@/api'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isSafari } from '@/utils/utils'
import ResultNav from '@/components/dump/Result/ResultNav.vue'
import Prefooter from '@/components/dump/Prefooter.vue'
import Addons from '@/components/smart/Addons.vue'
import Plugins from '@/components/dump/Plugins.vue'
import DeleteBtn from '@/components/smart/DeleteBtn.vue'
import DeleteConfirmation from '@/components/smart/DeleteConfirmation.vue'
import BreadCrumbs from '@/components/dump/Details/BreadCrumbs'
import SectorTitle from '@/components/dump/Details/SectorTitle'
import CalculationParams from '@/components/dump/Details/CalculationParams'
import SectorParams from '@/components/dump/Details/sectorParams'
import SvgWrapper from '@/components/dump/Figures/SvgWrapper'
import TableResult from '@/components/dump/Details/TableResult'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  data: () => ({
    isEditable: false,
    isShowDeleteConfirmation: false,
    fullPage: true,
    isLoading: false
  }),
  components: {
    TableResult,
    SvgWrapper,
    SectorParams,
    CalculationParams,
    SectorTitle,
    BreadCrumbs,
    ResultNav,
    Addons,
    Plugins,
    Prefooter,
    DeleteBtn,
    DeleteConfirmation,
    Loading
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_STEP: 'UPDATE_SECTOR_STEP',
      UPDATE_SECTOR_PARAM: 'UPDATE_SECTOR_PARAM',
      DELETE_SECTOR: 'DELETE_SECTOR',
      UPDATE_STATE_OBJECT: 'UPDATE_STATE_OBJECT'
    }),
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    toggleLoading(value) {
      this.isLoading = value
    },
    showDeleteConfirmation() {
      this.isShowDeleteConfirmation = true
    },
    print() {
      window.print()
    },
    focusInput() {
      this.isEditable = true
      this.$refs.title__input.focus()
    },
    getMaterial(id) {
      return this.allMaterials.find(p => p.id === id)
    },
    updateSectorName(val) {
      this.UPDATE_SECTOR_PARAM({
        id: this.sectorId,
        param: 'sectorName',
        val
      })
    },
    savePdf() {
      const [sector] = prepareSectorsForPdf(
        this.location,
        [this.sectors[this.sectorId]],
        [this.resultSectors[this.sectorId]],
        this.insulationMaterials,
        this.allMaterials,
        this.mountingTypes
      )
      this.isLoading = true
      // let materialType = ''
      const materialsTable = prepareTotalMaterialsTableForPdf(
        this.resultTotal.materials,
        this.sectors,
        this.allMaterials
      ).filter(sector => sector.uid === this.sectorFromState.uid)
      getSectorResultPdf(
        this.userInfo,
        this.sectorResultFromState.albums,
        sector,
        materialsTable,
        this.$store.getters.getDisclaimer,
        this.publicCalculationLink,
        this.location
      )
        .then(response => {
          // console.log(response);
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' })
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)
          // Open the URL on new Window
          this.isLoading = false
          if (this.isSafari) {
            const fileName = 'Детальный расчет по участку.pdf'
            saveAs(file, fileName)
          } else {
            window.open(fileURL)
          }
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    edit() {
      this.$router.push(`/wizard/${this.sectorId}/`)
      this.resetSaveCalculationResult()
      this.UPDATE_SECTOR_STEP({
        id: this.sectorId,
        val: 'Base'
      })
    },
    deleteSector() {
      this.resetSaveCalculationResult()
      if (this.sectors.length - 1 === parseInt(this.sectorId, 10)) {
        this.DELETE_SECTOR(this.sectorId)
        this.$router.push('/result')
        this.$root.onBodyScroll()
      } else {
        this.DELETE_SECTOR(this.sectorId)
        this.$root.onBodyScroll()
      }
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
    }
  },
  watch: {
    isShowDeleteConfirmation() {
      this.isShowDeleteConfirmation ? this.$root.offBodyScroll() : this.$root.onBodyScroll()
    }
  },
  mounted() {
    if (!this.location.zone) {
      this.$router.push('/')
    } else {
      this.$emit('updateStep', stepNumbers.details)
    }
  },
  computed: {
    ...mapState({
      defaultSvgParams: state => state.defaultSvgParams,
      insulationMaterials: state => state.insulationMaterials,
      allMaterials: state => state.materials,
      location: state => state.location,
      sectors: state => state.sectors,
      resultSectors: state => state.result.sectors,
      userInfo: state => state.userInfo,
      resultTotal: state => state.result.total,
      mountingTypes: state => state.mounting
    }),
    ...mapGetters({
      publicCalculationLink: 'publicCalculationLink'
    }),
    rollWidthOption() {
      return this.sectors[this.sectorId].material.rollWidthOption
    },
    tableData() {
      return this.sectorResultFromState.waterproofFixation
    },
    currentFigureComponent() {
      return formsComponents[this.buildingData.form]
    },
    isSafari() {
      return isSafari()
    },
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorId)
    },
    sectorResultFromState() {
      return this.$store.getters.getSectorResult(this.sectorId)
    },
    currentMaterial() {
      return this.insulationMaterials.find(p => p.id === this.sectorFromState.base.id)
    },
    mountingMethod() {
      return this.sectorFromState.material.mounting === 'mechanical'
        ? 'DetailsMechanicalFixation'
        : 'DetailsBallastFixation'
    },
    dampCourseMaterialName() {
      return this.allMaterials.find(material => material.id === this.sectorFromState.material.id)
        .name
    },
    buildingData() {
      return this.sectorFromState.building
    }
  }
}
</script>
<style lang="sass" scoped>
.fading-enter-active, .fading-leave-active
  transition: opacity .5s
.fading-enter, .fading-leave-to
  opacity: 0
.print
  background: none
  border: none
  box-shadow: none
  outline: none
  cursor: pointer
  position: absolute
  top: 0
  right: 0
  +media((padding: (320: rem(17), 992: rem(27))))
.details
  &__pane
    margin-bottom: rem(34)
  &__header
    width: 100%
    +media((padding: (320: rem(32) rem(24), 992: rem(34) 0 rem(39))))
  &__sectorParams
    &-item
      &--single
        +media((grid-template-columns: (320: 5fr 4fr, 360: 1fr 1fr)))
        &:last-of-type
          padding-bottom: rem(10)
      &:first-of-type
        .val
          +media((padding-right: (650: rem(20), 800: 0)))
</style>
