<template>
  <div class="details__header-nav">
    <router-link to="/result" class="btn">
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.99412 5H14M4.82353 9L1 5L4.82353 1"
          stroke="#E30713"
          stroke-width="2"
          stroke-miterlimit="22.9256"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
      Общий расчет
    </router-link>
    <div class="label">Расчет участка {{ sectorNumber }}</div>
  </div>
</template>

<script>
export default {
  props: {
    sectorId: {
      type: String,
      default: null,
      required: true
    }
  },
  name: 'BreadCrumbs',
  computed: {
    sectorNumber() {
      return +this.sectorId + 1
    }
  }
}
</script>

<style scoped lang="sass">
.details
  &__header
    &-nav
      display: flex
      flex-direction: row
      align-items: center
      +media((padding-bottom: (320: rem(16), 992: rem(21))))
      .btn
        color: $red
        text-decoration: none
        box-shadow: none
        outline: none
        font-style: normal
        font-weight: 600
        font-size: rem(12)
        line-height: rem(24)
        border: rem(1) solid rgba($red, .5)
        box-sizing: border-box
        border-radius: rem(4)
        padding: 0 rem(19) 0 rem(13)
        transition: .5s
        margin-right: rem(4)
        height: rem(34)
        display: flex
        flex-direction: row
        align-items: center
        svg
          margin-right: rem(13)
          path
            transition: .5s
        &:hover
          background: $red-hover
          border-color: $red-hover
          color: $white
          svg
            path
              stroke: $white
      .label
        background: $semi-white
        border-radius: rem(4)
        min-width: rem(142)
        text-align: center
        padding: 0 rem(22)
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(24)
        height: rem(34)
        flex-direction: row
        align-items: center
        color: $light_black
        +media((display: (320: none, 480: flex)))
</style>
